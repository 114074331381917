<template lang="html">

  <section class="private-policy">
    <div class="container text-legals">
      <h1 class="title">{{$t('menus.footer.privatePolicy')}}</h1>
      <article v-html="$t('legalTexts.privatePolicy')"></article>
    </div>
  </section>

</template>

<script lang="js">

  export default {
    name: 'private-policy',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .private-policy {}
</style>